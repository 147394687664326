import "./cookiedisclaimer.scss";
//@ts-ignore
window.CookieDisclaimer = window.CookieDisclaimer || {};
import CookieDisclaimer from "./CookieDisclaimer.svelte";
function attachCookieDisclaimer(target, props = {}) {
    new CookieDisclaimer({
        target,
        props,
    });
}
//@ts-ignore
window.CookieDisclaimer.attachCookieDisclaimer = attachCookieDisclaimer;
