<script lang="ts">
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";

  let showSettings: boolean = false;
  let shown: boolean = false;
  let hasNotSaved: boolean = false;

  export let initialHidden: boolean = false;

  export let labels: {
    learnMore: string;
    intro: {
      title: string;
      text: string;
    };
    settings: {
      title: string;
      text: string;
    };
    buttons: {
      acceptAll: string;
      declineAll: string;
      saveSettings: string;
      settings: string;
    };
  };
  export let privacyUrl: string = "/datenschutz";
  export let events: Record<string, () => any>;

  interface Category {
    name: string;
    id: string;
    description: string;
    required: boolean;
    checked?: boolean;
  }

  export let categories: Category[] = [];

  onMount(function () {
    const savedCategories = localStorage.getItem("cnds");
    if (initialHidden && !savedCategories) {
      hasNotSaved = true;
      return;
    }
    if (!savedCategories) {
      categories = categories.map(function (cat) {
        if (cat.required) {
          cat.checked = true;
        }
        return cat;
      });
      shown = true;
      hasNotSaved = true;
      document.body.style.overflow = "hidden";
      return;
    }
    const sCategories = savedCategories.split(",");
    categories = categories.map(function (cat, i) {
      if (sCategories.includes(i + "") || cat.required) {
        if (events[cat.id]) {
          events[cat.id]();
        }
        cat.checked = true;
      }
      return cat;
    });
  });

  function openDisclaimer() {
    if (hasNotSaved) {
      categories = categories.map(function (cat) {
        if (cat.required) {
          cat.checked = true;
        }
        return cat;
      });
      shown = true;
      document.body.style.overflow = "hidden";
    }
  }

  function viewSettings() {
    shown = true;
    showSettings = true;
    document.body.style.overflow = "hidden";
  }

  //@ts-ignore
  window.CookieDisclaimer.openCookieDisclaimer = openDisclaimer;
  //@ts-ignore
  window.CookieDisclaimer.showCookieSettings = viewSettings;

  function closeDisclaimer() {
    shown = false;
    document.body.style.overflow = "auto";
  }

  function saveSettings() {
    localStorage.setItem(
      "cnds",
      categories
        .map(function (cat, i) {
          if (cat.checked) {
            if (events[cat.id]) {
              events[cat.id]();
            }
            return i;
          }
        })
        .filter(function (cat) {
          if (cat !== null) {
            return true;
          }
          return false;
        })
        .join(",")
    );
    closeDisclaimer();
  }

  function allowAll() {
    categories = categories.map(function (cat) {
      if (events[cat.id]) {
        events[cat.id]();
      }
      cat.checked = true;
      return cat;
    });
    localStorage.setItem(
      "cnds",
      categories
        .map(function (cat, i) {
          return i;
        })
        .join(",")
    );
    closeDisclaimer();
  }

  function declineAll() {
    categories = categories.map(function (cat) {
      if (cat.required) return cat;
      cat.checked = false;
      return cat;
    });
    localStorage.setItem(
      "cnds",
      JSON.stringify(
        categories.findIndex(function (cat) {
          return cat.required;
        })
      )
    );
    closeDisclaimer();
  }
</script>

{#if shown}
  <div class="cookie-disclaimer">
    <div class="cookie-disclaimer_backdrop" />
    {#if showSettings}
      <div transition:fade class="cookie-disclaimer_content">
        <div class="cookie-disclaimer_head">
          <h3>{labels.settings.title}</h3>
          {#if !hasNotSaved}
            <button on:click={saveSettings} class="cookie-disclaimer_close">
              <svg
                width="35"
                height="35"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                ><path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                /></svg
              >
            </button>
          {/if}
        </div>
        <div class="cookie-disclaimer_main">
          <p class="cookie-disclaimer_intro">{labels.settings.text}</p>
          <a class="cookie-disclaimer_learn_more" href={privacyUrl}
            >{labels.learnMore}</a
          >
          <div class="cookie-disclaimer_btns">
            <button on:click={allowAll} class="cookie-disclaimer_btn"
              >{labels.buttons.acceptAll}</button
            >
            <button on:click={declineAll} class="cookie-disclaimer_btn"
              >{labels.buttons.declineAll}</button
            >
          </div>
          <div class="cookie-disclaimer_categories">
            {#each categories as category, i (category.name)}
              <div class="cookie-disclaimer_category">
                <div class="cookie-disclaimer_switch">
                  <input
                    aria-labelledby="cookie_{category.name
                      .split(' ')
                      .join('')}_title"
                    type="checkbox"
                    disabled={category.required}
                    checked={category.checked || category.required}
                    on:change={function () {
                      categories[i].checked = !categories[i].checked;
                      categories = categories;
                    }}
                    id="cookie_{category.name.split(' ').join('')}"
                  />
                  <label
                    aria-hidden="true"
                    tabindex="-1"
                    for="cookie_{category.name.split(' ').join('')}"
                  />
                </div>
                <div
                  class="cookie-disclaimer_category_content"
                  id="cookie_{category.name.split(' ').join('')}_title"
                >
                  <h4>{category.name}</h4>
                  <p>{category.description}</p>
                </div>
              </div>
            {/each}
          </div>
        </div>
        <div class="cookie-disclaimer_footer">
          <button on:click={saveSettings} class="cookie-disclaimer_btn primary">
            {labels.buttons.saveSettings}
          </button>
        </div>
      </div>
    {:else}
      <div class="cookie-disclaimer_intro_content">
        <h3>{labels.intro.title}</h3>
        <p>
          {labels.intro.text}
        </p>
        <a class="cookie-disclaimer_learn_more" href={privacyUrl}
          >{labels.learnMore}</a
        >
        <div class="cookie-disclaimer_intro_btns">
          <button on:click={allowAll} class="cookie-disclaimer_btn primary">
            {labels.buttons.acceptAll}
          </button>
          <button on:click={viewSettings} class="cookie-disclaimer_btn">
            {labels.buttons.settings}
          </button>
        </div>
      </div>
    {/if}
  </div>
{/if}
